import { memo, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { message, Spin } from "antd";

import { QuestionnaireReact } from "@iguhealth/questionnaire-component";
import {
  Questionnaire,
  QuestionnaireResponse,
  ValueSet,
} from "@iguhealth/fhir-types/r4/types";
import { r4 } from "@iguhealth/generated-ops";

import { client } from "../store/data";

const QuestionnairReactMemo = memo(
  QuestionnaireReact,
  (prevProps, nextProps) => {
    return (
      prevProps.id === nextProps.id &&
      prevProps.questionnaire?.id === nextProps.questionnaire?.id &&
      prevProps.questionnaireResponse?.id ===
        nextProps.questionnaireResponse?.id
    );
  }
);

export default function QuestionnaireForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const fhirClient = useRecoilValue(client);
  const params = useParams();
  const [questionnaireResponse, setQuestionnaireResponse] =
    useState<QuestionnaireResponse>();
  const [questionnaire, setQuestionnaire] = useState<Questionnaire>();
  useEffect(() => {
    setLoading(true);
    Promise.all([
      fhirClient?.read({}, "Questionnaire", params.id as string),
      fhirClient?.read({}, "QuestionnaireResponse", params.qrid as string),
    ]).then(([q, qr]) => {
      setQuestionnaire(q);
      setQuestionnaireResponse(qr);
      setLoading(false);
    });
  }, [fhirClient, params.id, params.qrid]);
  return (
    <Spin spinning={loading}>
      {questionnaire && questionnaireResponse?.id && (
        <>
          <div className="border border-slate-200 p-1 mt-4">
            <QuestionnairReactMemo
              id={params.qrid as string}
              questionnaire={questionnaire}
              questionnaireResponse={questionnaireResponse}
              onChange={(qr) => {
                setQuestionnaireResponse(qr);
              }}
              expand={async (url) => {
                const response = (await fhirClient?.invoke_type(
                  r4.ValueSetExpand.Op,
                  {},
                  "ValueSet",
                  {
                    url,
                  }
                )) as ValueSet;
                return response;
              }}
            />
          </div>
          <div className="mt-2 flex justify-end items-center">
            <button
              className="p-2 btn font-bold rounded bg-blue-500 text-white text-large"
              onClick={(e) => {
                if (questionnaireResponse) {
                  fhirClient
                    ?.update(
                      {},
                      {
                        ...questionnaireResponse,
                        questionnaire: `Questionnaire/${questionnaire.id}`,
                      }
                    )
                    .then((qr) => {
                      setQuestionnaireResponse(qr);
                      message.success(
                        "QuestionnaireResponse was successfully submitted."
                      );
                      navigate(`/Questionnaire/${params.id}`);
                    });
                }
              }}
            >
              Submit
            </button>
          </div>
        </>
      )}
    </Spin>
  );
}
