import "@iguhealth/questionnaire-component/css/antd.min.css";
import "@iguhealth/questionnaire-component/css/custom.css";

import { useEffect } from "react";
import FHIR from "fhirclient";

import httpClient from "@iguhealth/client/lib/http";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useRecoilState } from "recoil";

import { client } from "./store/data";
import QuestionnaireResponseList from "./views/QuestionnaireResponseList";
import QuestionnaireList from "./views/QuestionnaireList";
import QuestionnaireForm from "./views/QuestionnaireForm";

async function httpclient(...args: Parameters<typeof FHIR.oauth2.init>) {
  let client = await FHIR.oauth2.init(...args);
  return httpClient({
    getAccessToken: async function () {
      const expiresAt = client.state.expiresAt;
      if (
        expiresAt &&
        new Date(expiresAt * 1000).getTime() < new Date().getTime()
      ) {
        await FHIR.oauth2.authorize(...args);
        client = await FHIR.oauth2.init(...args);
      }

      const accessToken = client.state.tokenResponse?.access_token;
      return accessToken ? accessToken : "";
    },
    url: client.state.serverUrl,
  });
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <QuestionnaireList />,
  },
  {
    path: "/Questionnaire/:questionnaire",
    element: <QuestionnaireResponseList />,
  },
  {
    path: "/Questionnaire/:id/:qrid",
    element: <QuestionnaireForm />,
  },
]);

function App() {
  const [curClient, setClient] = useRecoilState(client);
  useEffect(() => {
    httpclient({
      //iss: "https://launch.smarthealthit.org/v/r4/sim/WzIsIiIsIiIsIkFVVE8iLDAsMCwwLCIiLCIiLCIiLCIiLCIiLCIiLCIiLDAsMV0/fhir",
      client_id: "my_web_app",
      scope: "patient/*.read",
    }).then((client) => {
      setClient(client);
    });
  }, [setClient]);

  return (
    <div className="flex items-center justify-center">
      {curClient && (
        <div className="container">
          <RouterProvider router={router} />
        </div>
      )}
    </div>
  );
}

export default App;
