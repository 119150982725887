import { Table } from "antd";
import { useRecoilValue } from "recoil";
import { useEffect, useState } from "react";
import { Questionnaire, QuestionnaireResponse } from "@iguhealth/fhir-types";
import { useParams, useNavigate } from "react-router-dom";

import { client } from "../store/data";

export default function QuestionnaireResponseList() {
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [questionnaire, setQuestionnaire] = useState<Questionnaire>();
  const [questionnaireResponses, setQuestionnaireResponses] = useState<
    QuestionnaireResponse[]
  >([]);
  const navigate = useNavigate();
  const fhirclient = useRecoilValue(client);

  useEffect(() => {
    console.log();
    setLoading(true);
    Promise.all([
      fhirclient?.read({}, "Questionnaire", params.questionnaire as string),
      fhirclient
        ?.search_type({}, "QuestionnaireResponse", [
          {
            name: "questionnaire",
            value: [params.questionnaire as string],
          },
        ])
        .then((d) => d.resources),
    ]).then(
      ([q, qrs]: [
        Questionnaire | undefined,
        QuestionnaireResponse[] | undefined
      ]) => {
        if (q) setQuestionnaire(q);
        if (qrs) setQuestionnaireResponses(qrs);

        setLoading(false);
      }
    );
  }, [fhirclient, params.questionnaire]);

  return (
    <div className="mt-4">
      {questionnaire && (
        <>
          <div className="mb-2">
            <h1 className="text-2xl font-semibold text-slate-700 mb-1">
              {questionnaire.title}
            </h1>
            <div className="items-center flex">
              <div className="flex flex-1">
                <span>{questionnaire.description}</span>
              </div>
              <div
                className="cursor-pointer p-2 btn font-bold rounded hover:bg-blue-600 bg-blue-500 text-white text-large"
                onClick={(e) => {
                  const qr: QuestionnaireResponse = {
                    resourceType: "QuestionnaireResponse",
                    questionnaire: questionnaire.url,
                    status: "in-progress",
                  };
                  fhirclient?.create({}, qr).then((qr) => {
                    navigate(
                      `/Questionnaire/${params.questionnaire}/${
                        qr.id as string
                      }`
                    );
                  });
                }}
              >
                Create
              </div>
            </div>
          </div>
          <Table
            loading={loading}
            rowClassName="cursor-pointer"
            rowKey="id"
            dataSource={questionnaireResponses}
            onRow={(record, rowIndex) => {
              return {
                onClick: (_event) => {
                  navigate(
                    `/Questionnaire/${params.questionnaire}/${record.id}`
                  );
                },
              };
            }}
            columns={[
              {
                title: "Status",
                dataIndex: ["status"],
                key: "status",
              },
              {
                title: "LastUpdated",
                dataIndex: ["meta", "lastUpdated"],
                key: "lastUpdated",
              },
            ]}
          ></Table>
        </>
      )}
    </div>
  );
}
