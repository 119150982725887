import { atom } from "recoil";
import { ResourceType, id, AResource } from "@iguhealth/fhir-types";
import httpClient from "@iguhealth/client/lib/http";

type FHIRStore<K extends ResourceType> = {
  [P in K]?: { [ID in id]: AResource<K> };
};

const initialState: FHIRStore<ResourceType> = {};

export const fhirStore = atom<FHIRStore<ResourceType>>({
  key: "fhir", // unique ID (with respect to other atoms/selectors)
  default: initialState, // default value (aka initial value)
});

export const client = atom<ReturnType<typeof httpClient> | undefined>({
  key: "client",
  default: undefined,
});
