import { Table } from "antd";
import { useEffect, useState } from "react";
import { Questionnaire } from "@iguhealth/fhir-types";
import { useNavigate } from "react-router-dom";

import prepareBundle from "../templates/prepare";
import { client } from "../store/data";
import { useRecoilValue } from "recoil";

export default function QuestionnaireList() {
  const fhirclient = useRecoilValue(client);
  const [loading, setLoading] = useState<boolean>(false);
  const [questionnaires, setQuestionnaires] = useState<Questionnaire[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    fhirclient
      ?.search_type({}, "Questionnaire", [
        { name: "_sort", value: ["-_lastUpdated"] },
      ])
      .then((d) => {
        setQuestionnaires(d.resources);
        setLoading(false);
      });
  }, [fhirclient]);

  return (
    <div className="mt-4">
      <div className="flex items-center justify-between mb-2">
        <h1 className="text-2xl font-semibold text-slate-700 mb-2">
          Questionnaires
        </h1>
        <div
          className="cursor-pointer p-2 btn font-bold rounded hover:bg-blue-600 bg-blue-500 text-white text-large"
          onClick={(e) => {
            fhirclient?.transaction({}, prepareBundle).then((bundle) => {
              const questionnaire = bundle.entry?.find(
                (e) => e.resource?.resourceType === "Questionnaire"
              )?.resource;
              if (questionnaire) {
                navigate(`/Questionnaire/${questionnaire.id as string}`);
              }
            });
          }}
        >
          Import
        </div>
      </div>
      <Table
        loading={loading}
        rowKey="id"
        rowClassName="cursor-pointer"
        dataSource={questionnaires}
        onRow={(record, rowIndex) => {
          return {
            onClick: (_event) => {
              navigate(`/Questionnaire/${record.id}`);
            },
          };
        }}
        columns={[
          {
            title: "Name",
            dataIndex: "title",
            key: "name",
          },
          {
            title: "Status",
            dataIndex: ["status"],
            key: "status",
          },
          {
            title: "LastUpdated",
            dataIndex: ["meta", "lastUpdated"],
            key: "lastUpdated",
          },
        ]}
      ></Table>
    </div>
  );
}
