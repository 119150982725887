import { Bundle } from "@iguhealth/fhir-types";

const transaction: Bundle = {
  resourceType: "Bundle",
  type: "transaction",
  entry: [
    {
      fullUrl: "yes-no-codesystem",
      resource: {
        content: "complete",
        name: "PRAPARE Yes No No-answer ",
        resourceType: "CodeSystem",
        title: "PRAPARE Yes No No-answer",
        status: "draft",
        url: "https://genfhi.com/CodeSystem/loinc-yes-or-no-notanswer",
        concept: [
          {
            code: "LA33-6",
            display: "Yes",
          },
          {
            code: "LA32-8",
            display: "No",
          },
          {
            code: "LA30122-8",
            display: "I choose not to answer this question",
          },
        ],
      },
      request: {
        method: "POST",
        url: "CodeSystem",
        ifNoneExist:
          "CodeSystem?url=https://genfhi.com/CodeSystem/loinc-yes-or-no-notanswer",
      },
    },
    {
      fullUrl: "yes-no-valueset",
      resource: {
        url: "https://genfhi.com/ValueSet/loinc-yes-or-no-notanswer",
        name: "PRAPARE Yes No Not-answer",
        status: "draft",
        compose: {
          include: [
            {
              system: "https://genfhi.com/CodeSystem/loinc-yes-or-no-notanswer",
            },
          ],
        },
        resourceType: "ValueSet",
      },
      request: {
        method: "POST",
        url: "ValueSet",
        ifNoneExist:
          "ValueSet?url=https://genfhi.com/ValueSet/loinc-yes-or-no-notanswer",
      },
    },
    {
      fullUrl: "questionnaire/prepare",
      resource: {
        url: "https://iguhealth.app/Questionnaire/PREPARE",
        item: [
          {
            item: [
              {
                text: "Are you Hispanic or Latino?",
                type: "choice",
                linkId: "hispanic-or-latino",
                prefix: "1)",
                extension: [
                  {
                    url: "http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl",
                    valueCodeableConcept: {
                      coding: [
                        {
                          code: "radio-button",
                          display: "Drop down",
                        },
                      ],
                    },
                  },
                ],
                answerValueSet:
                  "https://genfhi.com/ValueSet/loinc-yes-or-no-notanswer",
              },
              {
                text: "Which race(s) are you? Check all that apply",
                type: "choice",
                linkId: "race",
                prefix: "2)",
                extension: [
                  {
                    url: "http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl",
                    valueCodeableConcept: {
                      coding: [
                        {
                          code: "drop-down",
                          display: "Drop down",
                        },
                      ],
                    },
                  },
                ],
                answerOption: [
                  {
                    valueCoding: {
                      code: "LA6156-9",
                      display: "Asian",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA14045-1",
                      display: "Native Hawaiian",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30187-1",
                      display: "Pacific Islander",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA14042-8",
                      display: "Black/African American",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA4457-3",
                      display: "White",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA4-4",
                      display: "American Indian/Alaskan Native",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA46-8",
                      display: "Other, Please write",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30122-8",
                      display: "I choose not to answer this question",
                    },
                  },
                ],
              },
              {
                text: "Please enter your race",
                type: "string",
                linkId: "other-race",
                enableWhen: [
                  {
                    operator: "=",
                    question: "race",
                    answerCoding: {
                      code: "LA46-8",
                      display: "Other, Please write",
                    },
                  },
                ],
                enableBehavior: "all",
              },
              {
                text: "At any point in the past 2 years, has season or migrant farm work been your or your family's main source of income?",
                type: "choice",
                linkId: "choice-93872995",
                prefix: "3)",
                extension: [
                  {
                    url: "http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl",
                    valueCodeableConcept: {
                      coding: [
                        {
                          code: "drop-down",
                          display: "Drop down",
                        },
                      ],
                    },
                  },
                ],
                answerValueSet:
                  "https://genfhi.com/ValueSet/loinc-yes-or-no-notanswer",
              },
              {
                text: "Have you been discharged from the armed forces of the United States?",
                type: "choice",
                linkId: "choice-14526804",
                prefix: "4)",
                extension: [
                  {
                    url: "http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl",
                    valueCodeableConcept: {
                      coding: [
                        {
                          code: "drop-down",
                          display: "Drop down",
                        },
                      ],
                    },
                  },
                ],
                answerValueSet:
                  "https://genfhi.com/ValueSet/loinc-yes-or-no-notanswer",
              },
              {
                text: "What language are you most comfortable speaking?",
                type: "choice",
                linkId: "language",
                prefix: "5)",
                extension: [
                  {
                    url: "http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl",
                    valueCodeableConcept: {
                      coding: [
                        {
                          code: "drop-down",
                          display: "Drop down",
                        },
                      ],
                    },
                  },
                ],
                answerOption: [
                  {
                    valueCoding: {
                      code: "LA43-5",
                      display: "English",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30188-9",
                      display: "Language other than English, Please write",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30122-8",
                      display: "I choose not to answer this question",
                    },
                  },
                ],
              },
              {
                text: "Enter the other language you are comfortable speaking with",
                type: "string",
                linkId: "string-47380890",
                enableWhen: [
                  {
                    operator: "=",
                    question: "language",
                    answerCoding: {
                      code: "LA30188-9",
                      display: "Language other than English, Please write",
                    },
                  },
                ],
                enableBehavior: "all",
              },
            ],
            text: "Personal Characteristics",
            type: "group",
            linkId: "personal-characteristics",
          },
          {
            item: [
              {
                text: "How many family members, including yourself, do you currently live with?",
                type: "integer",
                linkId: "integer-47862824",
                prefix: "6)",
                enableBehavior: "all",
              },
              {
                text: "What is your housing situation today?",
                type: "choice",
                linkId: "housing-situation",
                prefix: "7)",
                extension: [
                  {
                    url: "http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl",
                    valueCodeableConcept: {
                      coding: [
                        {
                          code: "drop-down",
                          display: "Drop down",
                        },
                      ],
                    },
                  },
                ],
                answerOption: [
                  {
                    valueCoding: {
                      code: "LA30189-7",
                      display: "I have housing",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30190-5",
                      display:
                        "I do not have housing (staying with others, in a hotel, in a shelter, living outside on the street, on a beach, in a car, or in a park)",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30122-8",
                      display: "I choose not to answer this question",
                    },
                  },
                ],
              },
              {
                text: "Are you worried about losing your housing?",
                type: "choice",
                linkId: "choice-76231824",
                prefix: "8)",
                extension: [
                  {
                    url: "http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl",
                    valueCodeableConcept: {
                      coding: [
                        {
                          code: "drop-down",
                          display: "Drop down",
                        },
                      ],
                    },
                  },
                ],
                enableWhen: [
                  {
                    operator: "=",
                    question: "housing-situation",
                    answerCoding: {
                      code: "LA30189-7",
                      display: "I have housing",
                    },
                  },
                ],
                answerValueSet:
                  "https://genfhi.com/ValueSet/loinc-yes-or-no-notanswer",
              },
              {
                text: "What address do you live at?",
                type: "display",
                linkId: "display-62716082",
                prefix: "9)",
              },
              {
                text: "Street:",
                type: "string",
                linkId: "string-4583759",
              },
              {
                text: "City, State, Zip code:",
                type: "string",
                linkId: "string-57147660",
              },
            ],
            text: "Family and Home",
            type: "group",
            linkId: "group-36338790",
          },
          {
            item: [
              {
                text: "What is the highest level of school that you have finished?",
                type: "choice",
                linkId: "choice-72822375",
                prefix: "10)",
                extension: [
                  {
                    url: "http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl",
                    valueCodeableConcept: {
                      coding: [
                        {
                          code: "drop-down",
                          display: "Drop down",
                        },
                      ],
                    },
                  },
                ],
                answerOption: [
                  {
                    valueCoding: {
                      code: "LA30191-3",
                      display: "Less than high school degree",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30192-1",
                      display: "High school diploma or GED",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30193-9",
                      display: "More than high school",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30122-8",
                      display: "I choose not to answer this question",
                    },
                  },
                ],
              },
              {
                text: "What is your current work situation?",
                type: "choice",
                linkId: "current-work",
                prefix: "11)",
                extension: [
                  {
                    url: "http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl",
                    valueCodeableConcept: {
                      coding: [
                        {
                          code: "drop-down",
                          display: "Drop down",
                        },
                      ],
                    },
                  },
                ],
                answerOption: [
                  {
                    valueCoding: {
                      code: "LA17956-6",
                      display: "Unemployed",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30138-4",
                      display: "Part-time or temporary work",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30136-8",
                      display: "Full-time work",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30137-6",
                      display:
                        "Otherwise unemployed but not seeking work (ex: student, retired, disabled, unpaid primary care giver)",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30122-8",
                      display: "I choose not to answer this question",
                    },
                  },
                ],
              },
              {
                text: "Please write:",
                type: "string",
                linkId: "string-9541158",
                enableWhen: [
                  {
                    operator: "=",
                    question: "current-work",
                    answerCoding: {
                      code: "LA30137-6",
                      display:
                        "Otherwise unemployed but not seeking work (ex: student, retired, disabled, unpaid primary care giver), Please write",
                    },
                  },
                ],
              },
              {
                text: "What is your main insurance?",
                type: "choice",
                linkId: "choice-21780239",
                prefix: "12)",
                extension: [
                  {
                    url: "http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl",
                    valueCodeableConcept: {
                      coding: [
                        {
                          code: "drop-down",
                          display: "Drop down",
                        },
                      ],
                    },
                  },
                ],
                answerOption: [
                  {
                    valueCoding: {
                      code: "LA30194-7",
                      display: "None/uninsured",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA17849-3",
                      display: "Medicaid",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30195-4",
                      display: "CHIP Medicaid",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA15652-3",
                      display: "Medicare\t",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30196-2",
                      display: "Other public insurance (not CHIP)",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30197-0",
                      display: "Other public insurance (CHIP)",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA6350-8",
                      display: "Private insurance",
                    },
                  },
                ],
              },
              {
                text: "During the past year, what was the total combined income for you and the family members you live with? This information will help us determine if you are eligible for any benefits.",
                type: "decimal",
                linkId: "string-30079876",
                prefix: "13)",
                enableWhen: [
                  {
                    operator: "=",
                    question: "string-57147660",
                  },
                ],
                enableBehavior: "all",
              },
              {
                text: "In the past year, have you or any family members you live with been unable to get any of the following when it was really needed? Add all that apply.",
                type: "choice",
                linkId: "unable-to-get",
                prefix: "14)",
                repeats: true,
                answerOption: [
                  {
                    valueCoding: {
                      code: "LA30125-1",
                      display: "Food",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30126-9",
                      display: "Clothing",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30124-4",
                      display: "Utilities",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30127-7",
                      display: "Child care",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30128-5",
                      display:
                        "Medicine or Any Health Care (Medical, Dental, Mental Health, Vision)",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30129-3",
                      display: "Phone",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA46-8",
                      display: "Other, Please write",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30122-8",
                      display: "I choose not to answer this question",
                    },
                  },
                ],
              },
              {
                text: "Please write:",
                type: "string",
                linkId: "string-4458053",
                enableWhen: [
                  {
                    operator: "=",
                    question: "unable-to-get",
                    answerCoding: {
                      code: "LA46-8",
                      display: "Other, Please write",
                    },
                  },
                ],
                enableBehavior: "all",
              },
              {
                text: "Has lack of transportation kept you from medical appointments, meetings, work, or from getting things needed for daily living? Check all that apply.",
                type: "choice",
                linkId: "choice-61207066",
                prefix: "15)",
                extension: [
                  {
                    url: "http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl",
                    valueCodeableConcept: {
                      coding: [
                        {
                          code: "drop-down",
                          display: "Drop down",
                        },
                      ],
                    },
                  },
                ],
                answerOption: [
                  {
                    valueCoding: {
                      code: "LA30133-5",
                      display:
                        "Yes, it has kept me from medical appointments or from getting my medications",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30134-3",
                      display:
                        "Yes, it has kept me from non-medical meetings, appointments, work, or from getting things that I need",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA32-8",
                      display: "No",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30122-8",
                      display: "I choose not to answer this question",
                    },
                  },
                ],
              },
            ],
            text: "Money and Resources",
            type: "group",
            linkId: "group-22817579",
          },
          {
            item: [
              {
                text: "How often do you see or talk to people that you care about and feel close to? (For example: talking to friends on the phone, visiting friends or family, going to church or club meetings)",
                type: "choice",
                linkId: "choice-12272384",
                prefix: "16)",
                extension: [
                  {
                    url: "http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl",
                    valueCodeableConcept: {
                      coding: [
                        {
                          code: "drop-down",
                          display: "Drop down",
                        },
                      ],
                    },
                  },
                ],
                answerOption: [
                  {
                    valueCoding: {
                      code: "LA27722-0",
                      display: "Less than once a week",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30130-1",
                      display: "1 or 2 times a week",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30131-9",
                      display: "3 to 5 times a week",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30132-7",
                      display: "5 or more times a week",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30122-8",
                      display: "I choose not to answer this question",
                    },
                  },
                ],
              },
              {
                text: "Stress is when someone feels tense, nervous, anxious, or canât sleep at night because their mind is troubled. How stressed are you?",
                type: "choice",
                linkId: "choice-71197071",
                prefix: "17)",
                extension: [
                  {
                    url: "http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl",
                    valueCodeableConcept: {
                      coding: [
                        {
                          code: "drop-down",
                          display: "Drop down",
                        },
                      ],
                    },
                  },
                ],
                answerOption: [
                  {
                    valueCoding: {
                      code: "LA6568-5",
                      display: "Not at all",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA13863-8",
                      display: "A little bit",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA13909-9",
                      display: "Somewhat",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA13902-4",
                      display: "Quite a bit",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA13914-9",
                      display: "Very much",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30122-8",
                      display: "I choose not to answer this question",
                    },
                  },
                ],
              },
            ],
            text: "Social and Emotional Health",
            type: "group",
            linkId: "group-95619712",
          },
          {
            item: [
              {
                text: "In the past year, have you spent more than 2 nights in a row in a jail, prison, detention center, or juvenile correctional facility?",
                type: "choice",
                linkId: "choice-437726",
                prefix: "18)",
                extension: [
                  {
                    url: "http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl",
                    valueCodeableConcept: {
                      coding: [
                        {
                          code: "drop-down",
                          display: "Drop down",
                        },
                      ],
                    },
                  },
                ],
                answerValueSet:
                  "https://genfhi.com/ValueSet/loinc-yes-or-no-notanswer",
              },
              {
                text: "Are you a refugee?",
                type: "choice",
                linkId: "choice-21825453",
                prefix: "19)",
                extension: [
                  {
                    url: "http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl",
                    valueCodeableConcept: {
                      coding: [
                        {
                          code: "drop-down",
                          display: "Drop down",
                        },
                      ],
                    },
                  },
                ],
                answerValueSet:
                  "https://genfhi.com/ValueSet/loinc-yes-or-no-notanswer",
              },
              {
                text: "Do you feel physically and emotionally safe where you currently live?",
                type: "choice",
                linkId: "choice-19452606",
                prefix: "20)",
                extension: [
                  {
                    url: "http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl",
                    valueCodeableConcept: {
                      coding: [
                        {
                          code: "drop-down",
                          display: "Drop down",
                        },
                      ],
                    },
                  },
                ],
                answerOption: [
                  {
                    valueCoding: {
                      code: "LA33-6",
                      display: "Yes",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA32-8",
                      display: "No",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA14072-5",
                      display: "Unsure",
                    },
                  },
                  {
                    valueCoding: {
                      code: "LA30122-8",
                      display: "I choose not to answer this question",
                    },
                  },
                ],
              },
              {
                text: "In the past year, have you been afraid of your partner or ex-partner?",
                type: "choice",
                linkId: "choice-64795768",
                prefix: "21)",
                extension: [
                  {
                    url: "http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl",
                    valueCodeableConcept: {
                      coding: [
                        {
                          code: "drop-down",
                          display: "Drop down",
                        },
                      ],
                    },
                  },
                ],
                answerValueSet:
                  "https://genfhi.com/ValueSet/loinc-yes-or-no-notanswer",
              },
            ],
            text: "Optional Additional Questions",
            type: "group",
            linkId: "group-50882695",
          },
        ],
        title:
          "PRAPARE®: Protocol for Responding to and Assessing Patient Assets, Risks, and Experiences",
        status: "active",
        description:
          "Protocol for responding to and assessing patient assets, risks and experience",
        resourceType: "Questionnaire",
      },
      request: {
        method: "POST",
        url: "Questionnaire",
        ifNoneExist:
          "Questionnaire?url=https://iguhealth.app/Questionnaire/PREPARE",
      },
    },
  ],
};

export default transaction;
